import React, { Component } from "react";

export default class WP extends Component {

    render() {



        return (<>

            <div className="clearfix" style={{ clear: "both" }}></div>

            <div id="home" className="hero-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12" style={{ backgroundColor: "#CDCDCD", borderRadius: "20px", padding: "50px" }}>
                            <h1 style={{ textAlign: "center" }}><strong>CiroTrx White Paper</strong></h1>
                            <p>&nbsp;</p>
                            <p><strong>Date:</strong> December 2024</p>

                            <h2>1. Executive Summary</h2>
                            <p>CiroTrx is a decentralized platform built on the TRON network, designed to facilitate the seamless transfer of stablecoins and tokens with zero TRX fees. Our mission is to enhance blockchain accessibility and affordability for users worldwide.</p>

                            <h2>2. Introduction</h2>
                            <p>The TRON network is rapidly evolving as a hub for decentralized finance (DeFi). However, users often face challenges such as high fees and complex transaction processes. CiroTrx addresses these issues by leveraging TRON's high-performance blockchain to enable cost-efficient and user-friendly token transfers.</p>

                            <h2>3. Technology and Architecture</h2>
                            <p>At the core of CiroTrx lies a robust smart contract system that ensures secure, transparent, and low-cost transactions. Our decentralized application (DApp) allows users to connect wallets and perform operations with ease.</p>
                            <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                                <li><strong>Smart Contracts:</strong> Automate transactions while ensuring security.</li>
                                <li><strong>TRON Blockchain:</strong> Provides scalability and high-speed processing.</li>
                            </ul>

                            <h2>4. Use Cases</h2>
                            <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                                <li><strong>International Remittances:</strong> Fast and low-cost transfers for cross-border payments.</li>
                                <li><strong>DeFi Integration:</strong> Simplifies token transfers for decentralized financial applications.</li>
                            </ul>

                            <h2>5. Benefits</h2>
                            <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                                <li><strong>Zero Fees:</strong> Transfer stablecoins without incurring TRX costs.</li>
                                <li><strong>Fast Transactions:</strong> Leverages TRON's high throughput capabilities.</li>
                                <li><strong>User-Friendly:</strong> Easy-to-use DApp interface.</li>
                            </ul>

                            <h2>6. Roadmap</h2>
                            <p>Our development milestones include:</p>
                            <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                                <li><strong>Q1 2024:</strong> Beta release of the DApp.</li>
                                <li><strong>Q2 2024:</strong> Full-scale deployment and user onboarding.</li>
                                <li><strong>Q3 2024:</strong> Advanced features like staking and rewards programs.</li>
                            </ul>

                            <h2>7. Conclusion</h2>
                            <p>CiroTrx is poised to redefine the way users interact with stablecoins and tokens on the TRON network, making blockchain technology more accessible and cost-effective than ever before.</p>

                            <h2>8. Contact</h2>
                            <p>For more details, visit our website: <a href="/#contact" >https://cirotrx.com</a>.</p>

                        </div>
                    </div>
                </div>
            </div >

            <div className="clearfix" style={{ clear: "both" }}></div>

        </>);
    }
}
