import React, { Component } from 'react';

import { TronLinkAdapter } from '@tronweb3/tronwallet-adapter-tronlink';

import cons from "../cons.js";
import TronWeb from 'tronweb';

import abi_TOKENTRC20 from "../abi/tokenTRC20";
import abi_CIROFEE from "../abi/cirofee";

const BigNumber = require('bignumber.js');
function delay(s) { return new Promise(res => setTimeout(res, s * 1000)); }

const RED = process.env.REACT_APP_RED || "https://api.trongrid.io"

const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />


const tronWeb = new TronWeb({
  fullHost: RED,
})

const adapter = new TronLinkAdapter();
let arrancado = false;
let cargandoTokens = false;
let limiteEnergy = 200000

let Intervalo = null;

class CiroTrx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estatus: {
        loggedIn: false,
        installed: false,
        web3: null
      },
      contrato: {
        web3: null,
        ciro_trx: null
      },
      wallet: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      elementSelect: [<option key={"objets" + 0} value={0}>USDT (Tether USD) - Fee 0.1 USDT </option>],
      tokenSelected: "USDT",
      tokenBalance: new BigNumber(0),
      tokenContratos: [
        {
          "address": "41a614f803b6fd780986a42c78ec9c7f77e6ded13c",
          "symbol": "USDT",
          "name": "Tether USD",
          "decimals": 6,
          "fees": 0.1
        },
        {
          "address": "4194f24e992ca04b49c6f2a2753076ef8938ed4daa",
          "symbol": "USDD",
          "name": "Decentralized USD",
          "decimals": 18,
          "fees": 0.1
        },
        {
          "address": "413487b63d30b5b2c87fb7ffa8bcfade38eaac1abe",
          "symbol": "USDC",
          "name": "USD Coin",
          "decimals": 6,
          "fees": 0.1
        },
        {
          "address": "41cebde71077b830b958c8da17bcddeeb85d0bcf25",
          "symbol": "TUSD",
          "name": "TrueUSD",
          "decimals": 18,
          "fees": 0.1
        },
        {
          "address": "41148cf421e250b13e3ae00d65977bba14d70dd9f7",
          "symbol": "TCNH",
          "name": "TrueCNH",
          "decimals": 18,
          "fees": 3.5
        },
        {
          "address": "41834295921a488d9d42b4b3021ed1a3c39fb0f03e",
          "symbol": "USDJ",
          "name": "JUST Stablecoin v1.0",
          "decimals": 18,
          "fees": 0.1
        }
      ],
      recivedAmount: 0,
      idMoneda: 0,
      modalTitle: "Title",
      modalBody: "body",
      contractready: false,
      energyContrato: 0,
      energyWallet: 0,
      conectando: false,
      isChecked: false,

    };

    this.conectar = this.conectar.bind(this);

    this.send = this.send.bind(this);
    this.estado = this.estado.bind(this);
    this.opciones = this.opciones.bind(this);

    this.recursos = this.recursos.bind(this);
    this.rentEnergy = this.rentEnergy.bind(this);
    this.calcularRecurso = this.calcularRecurso.bind(this);
    this.approvalToken = this.approvalToken.bind(this);

  }

  async componentDidMount() {
    document.title = "Dapp | CiroTrx"

    setTimeout(async () => {
      this.conectar();
      if (this.state.estatus['loggedIn'] && this.state.contractready) {
        this.estado();

      }
    }, 3 * 1000);

    Intervalo = setInterval(async () => {

      if (this.state.estatus['loggedIn'] && this.state.contractready) {
        this.estado();

      }

    }, 30 * 1000);

  }

  async componentWillUnmount() {
    clearInterval(Intervalo)
  }

  async conectar() {

    let { estatus, wallet, contrato } = this.state;

    if (typeof window.tronWeb !== 'undefined') {


      await adapter.connect()
        .catch((e) => {
          console.log(e.toString())
          alert(e.toString())

        })

      //console.log(adapter)

      if (adapter.address) {
        console.log("%c TronLink Conectado", "color:green");
        estatus["loggedIn"] = true
        wallet = adapter.address

      } else {
        estatus["loggedIn"] = false
        wallet = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"

      }

      const tronWeb_c = new TronWeb({
        fullHost: RED,
        //headers: { "TRON-PRO-API-KEY": KEYS[1] }
      })

      contrato['web3'] = tronWeb_c;
      contrato['web3'].setAddress(wallet)

      this.setState({
        estatus,
        wallet

      });

      if (!arrancado && !this.state.contractready) {
        arrancado = true
        if (cons.SC !== "") {
          contrato.ciro_trx = await contrato['web3'].contract(abi_CIROFEE, cons.SC);

          contrato.tokenList = []

          let tokenList = await contrato.ciro_trx.tokenList().call();

          for (let index = 0; index < tokenList[0].length; index++) {
            contrato.tokenList[index] = await contrato['web3'].contract(abi_TOKENTRC20, tokenList[0][index]);
          }

          this.setState({
            contrato: contrato,
            contractready: true
          });

          this.estado()

        }
      }

    } else {


      this.setState({
        wallet: wallet

      });


    }
  }

  async estado() {

    let { wallet, tokenContratos, idMoneda } = this.state;

    let tokenBalance = await this.state.contrato.tokenList[idMoneda].balanceOf(wallet).call()
    if (tokenBalance._hex) tokenBalance = tokenBalance._hex
    tokenBalance = new BigNumber(tokenBalance).shiftedBy(-tokenContratos[idMoneda].decimals)

    this.setState({
      tokenSelected: tokenContratos[idMoneda].symbol,
      tokenBalance
    })

    let approved = await this.state.contrato.tokenList[idMoneda].allowance(this.state.wallet, this.state.contrato.ciro_trx.address).call()
    approved = new BigNumber(approved).shiftedBy(-tokenContratos[idMoneda].decimals).toNumber()

    let amountToken = await this.state.contrato.tokenList[idMoneda].balanceOf(this.state.wallet).call()
    if (amountToken._hex) {
      amountToken = amountToken._hex
    }
    amountToken = new BigNumber(amountToken).shiftedBy(-tokenContratos[idMoneda].decimals).toNumber()

    this.setState({

      approved: approved >= amountToken ? true : false
    })

    let cuenta = await this.state.contrato['web3'].trx.getAccountResources("TRrhyn55AtGEjgaLpj9sTbRGhqzVJ8ueNs");

    let energyContrato = 0
    if (cuenta.EnergyLimit) energyContrato = cuenta.EnergyLimit
    if (cuenta.EnergyUsed) energyContrato -= cuenta.EnergyUsed

    this.setState({
      energyContrato,
    })

    cuenta = await window.tronWeb.trx.getAccountResources(wallet);

    let energyWallet = 0
    if (cuenta.EnergyLimit) energyWallet = cuenta.EnergyLimit
    if (cuenta.EnergyUsed) energyWallet -= cuenta.EnergyUsed

    this.setState({
      energyWallet,
    })

    let balance = await this.state.contrato['web3'].trx.getUnconfirmedBalance(wallet);
    balance = new BigNumber(balance).shiftedBy(-6).toNumber();

    if (balance <= 0) {
      this.setState({
        modalTitle: "Alert!",
        modalBody: "Need some TRX to pay bandwidth, recharge at least 1 TRX to activate your account and use the contract"
      })

      window.$("#exampleModal").modal("show");
    }

    if (!cargandoTokens && this.state.contractready && this.state.contrato.tokenList.length > 0) {
      cargandoTokens = true

      let tokenList = await this.state.contrato.ciro_trx.tokenList().call();

      let elementSelect = [];

      let fees = [];

      for (let index = 0; index < tokenList[0].length; index++) {

        let moneda = {}
        moneda.address = this.state.contrato.tokenList[index].address
        moneda.symbol = await this.state.contrato.tokenList[index].symbol().call()
        //await delay(1)
        moneda.name = await this.state.contrato.tokenList[index].name().call()
        //await delay(1)
        let decimals = await this.state.contrato.tokenList[index].decimals().call()
        //await delay(1)
        if (decimals._hex) {
          decimals = parseInt(decimals._hex)
        }
        moneda.decimals = decimals

        let fee = 0;
        if (tokenList[2][index]) {
          fee = tokenList[1][index];
          if (fee._hex) {
            fee = fee._hex
          }

          fee = new BigNumber(fee).shiftedBy(-moneda.decimals)
          fees[index] = fee.toNumber()

          fee = fee.toString(10) + " " + moneda.symbol
        } else {
          fee = (tokenList[1][index] / tokenList[3][index]) * 100
          fees[index] = fee

          fee = fee + " %"
        }

        moneda.fees = fees[index]

        elementSelect[index] = <option key={"objets" + index} value={index}>{moneda.symbol} ({moneda.name}) - Fee {fee} </option>

        tokenContratos[index] = moneda

      }

      this.setState({
        elementSelect,
        tokenContratos: tokenContratos,
        fees: fees,
      })
    }

  }

  async opciones(from) {

    let { tokenContratos, tokenBalance, isChecked, recivedAmount } = this.state
    let idMoneda = document.getElementById("token").value;

    this.setState({ idMoneda })

    if (tokenContratos.length <= 0) return;
    let amountElement = document.getElementById("amount");
    let amount = (amountElement.value).replace(",", ".")
    if( isNaN(amount) ) {
      amountElement.value = recivedAmount
      return
    }
    if ( amount === "") amount = 0
    amount = new BigNumber(amount);
    recivedAmount = amount;

    if (tokenContratos[idMoneda].fees > amount.toNumber()) {
      recivedAmount = new BigNumber(0)
    } else {

      if (amount.toNumber() > tokenBalance.toNumber()) {
        amount = tokenBalance
      }

      if (isChecked) {
        amount = amount.plus(tokenContratos[idMoneda].fees)
      } else {
        if (from) {
          amount = amount.minus(tokenContratos[idMoneda].fees)

        }
      }

      if(parseFloat(amountElement.value) !== amount.toNumber()){
        amountElement.value = (amount.toString(10)).replace(",", ".")
      }
      recivedAmount = amount.minus(tokenContratos[idMoneda].fees)


    }

    this.setState({
      tokenSelected: tokenContratos[idMoneda].symbol,
      recivedAmount: recivedAmount.toString(10),
    });

  }

  async recursos() {
    var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "available"
    var consulta = await fetch(url).then((r) => r.json())

    var band = consulta["BANDWIDTH_-_Rental_duration_less_or_equal_to_3_days"]
    var energi = consulta["ENERGY_-_Rental_duration_less_or_equal_to_3_days"]

    this.setState({
      available_bandwidth: band,
      available_energy: energi,
      total_bandwidth_pool: consulta.total_bandwidth_pool,
      total_energy_pool: consulta.total_energy_pool
    });
  }

  async calcularRecurso(amount) {

    await this.recursos();
    let url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "prices"

    let body = { "resource": "ENERGY", "amount": amount, "duration": "5min" }

    this.setState({
      precio: "Calculating..."
    })

    var consulta2 = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((r) => r.json())

    //console.log(consulta2)

    let precio = consulta2.price * 1
    precio = parseInt(precio * 10 ** 6) / 10 ** 6

    return precio

  }

  async rentEnergy(cantidad) {

    let { wallet, energyWallet, contrato } = this.state

    cantidad = cantidad - energyWallet
    cantidad = cantidad < 32000 ? 32000 : cantidad

    let recurso = "energy"
    let time = "5min"
    let precio = await this.calcularRecurso(cantidad, time)

    if (energyWallet > cantidad) return true;

    if (precio > await this.state.contrato['web3'].trx.getUnconfirmedBalance(wallet) / 10 ** 6) {

      this.setState({
        modalTitle: <>This time only, you need {precio + 1} TRX</>,
        modalBody: <>To complete the energy rental for {cantidad.toLocaleString("en-US")} energy this first time with this token and then you can use it only having enough bandwidth for the transaction</>
      })

      window.$("#exampleModal").modal("show");
      return false;
    }


    this.setState({
      modalTitle: <>Confirm transaction {imgLoading}</>,
      modalBody: <>Please confirm the transaction to rent {cantidad.toLocaleString("en-US")} energy for {precio} TRX from your wallet </>
    })

    window.$("#exampleModal").modal("show");

    const unSignedTransaction = await tronWeb.transactionBuilder.sendTrx(process.env.REACT_APP_WALLET_API, (precio * 10 ** 6).toString(10), adapter.address);
    const signedTransaction = await adapter.signTransaction(unSignedTransaction)
      .catch((e) => {
        this.setState({
          modalTitle: "Transaction failed",
          modalBody: <>{e.toString()}
            <br /><br />
            <button type="button" className="btn btn-danger" onClick={() => { window.$("#exampleModal").modal("hide") }}>Close</button>
          </>
        })

        window.$("#exampleModal").modal("show");
        return false;
      })

    if (!signedTransaction) { return false; }

    let hash = await tronWeb.trx.sendRawTransaction(signedTransaction);

    this.setState({
      modalTitle: <>Waiting for the blockchain {imgLoading}</>,
      modalBody: <>We are waiting for the blockchain to process and confirm your transfer. This can take from 3 seconds to 1 minute<br />Transacction hash: {hash}</>
    })

    window.$("#exampleModal").modal("show");
    await delay(1.5);

    let envio = hash.transaction.raw_data.contract[0].parameter.value

    this.setState({
      modalTitle: <>we are verifying {imgLoading}</>,
      modalBody: "We are verifying that the amounts and the address to which the funds were sent are the correct, please do not close or exit the website as this may affect this process."
    })

    window.$("#exampleModal").modal("show");
    await delay(1.5);


    if (hash.result && envio.amount + "" === contrato['web3'].toSun(precio) && contrato['web3'].address.fromHex(envio.to_address) === process.env.REACT_APP_WALLET_API) {

      hash = await contrato['web3'].trx.getTransaction(hash.txid);

      if (hash.ret[0].contractRet === "SUCCESS") {

        this.setState({
          modalTitle: <>We are allocating your {recurso} {imgLoading}</>,
          modalBody: "Please do not close or leave the page as this will cause an error in the " + recurso + " allocation."
        })

        window.$("#exampleModal").modal("show");

        let url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + recurso

        let body = {
          "id_api": process.env.REACT_APP_USER_ID,
          "wallet": wallet,
          "amount": cantidad,
          "time": time,
          "user_id": "fromCiro"
        }

        let consulta2 = await fetch(url, {
          method: "POST",
          headers: {
            'token-api': process.env.REACT_APP_TOKEN,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }).then((r) => r.json())

        //console.log(consulta2)

        if (consulta2.response === 1) {

          this.setState({
            modalTitle: "Completed successfully",
            modalBody: <><p>Energy rental completed successfully. </p><button type="button" data-bs-dismiss="modal" className="btn btn-success">continue</button></>
          })
          window.$("#exampleModal").modal("show");

          return true;

        } else {

          let msg = consulta2.msg || "";

          this.setState({
            modalTitle: "Contact support",
            modalBody: "Please contact support for: " + hash.txID + " | " + msg
          })
          window.$("#exampleModal").modal("show");

        }

      } else {
        this.setState({
          modalTitle: "Contact support",
          modalBody: "Please contact support for: Error SUC-808831"
        })
        window.$("#exampleModal").modal("show");
      }


    } else {
      this.setState({
        modalTitle: "Contact support",
        modalBody: "Please contact support for: Error NN-0001"
      })
      window.$("#exampleModal").modal("show");
    }



  }

  async approvalToken() {

    let ciroTrxAddress = window.tronWeb.address.toHex(cons.SC)
    let tokenAddrees = this.state.tokenContratos[this.state.idMoneda].address

    let inputs = [
      { type: 'address', value: ciroTrxAddress },
      { type: 'uint256', value: "115792089237316195423570985008687907853269984665640564039457584007913129639935" }
    ]

    let funcion = "approve(address,uint256)"
    const options = {}//{ callValue: amount }
    let energiaRequerida = await window.tronWeb.transactionBuilder.triggerConstantContract(tokenAddrees, funcion, options, inputs, window.tronWeb.address.toHex(this.state.wallet))

    let eRequired = 0

    if (energiaRequerida.energy_used) eRequired = energiaRequerida.energy_used

    if (energiaRequerida.energy_penalty) {
      eRequired += energiaRequerida.energy_penalty
    }

    if (await this.rentEnergy(eRequired)) {

      let inputs = [
        { type: 'address', value: ciroTrxAddress },
        { type: 'uint256', value: "115792089237316195423570985008687907853269984665640564039457584007913129639935" }
      ]

      let funcion = "approve(address,uint256)"
      const options = {}//{ callValue: amount }
      const trigger = await tronWeb.transactionBuilder.triggerSmartContract(tokenAddrees, funcion, options, inputs, window.tronWeb.address.toHex(this.state.wallet))
      let transaction = await tronWeb.transactionBuilder.extendExpiration(trigger.transaction, 180)
      transaction = await adapter.signTransaction(transaction)
        .catch((e) => {

          this.setState({
            modalTitle: "Error",
            modalBody: e.toString()
          })

          window.$("#exampleModal").modal("show");
        })
      transaction = await tronWeb.trx.sendRawTransaction(transaction)
        .then(async (r) => {
          this.setState({
            ModalTitulo: "Corerct",
            ModalBody: <>Your aproving is done <br />Hash: {r.txid}</>
          })

          return r;
        })
        .catch((e) => {

          this.setState({
            modalTitle: "Error",
            modalBody: e.toString()
          })

          return false;
        })

      window.$("exampleModal").modal("show");
      if (transaction.txid) {
        await delay(3)

        this.send();
      }

    }

  }

  async send() {
    await this.estado();
    await this.opciones();

    let { approved, tokenContratos } = this.state

    const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />

    let idMoneda = document.getElementById("token").value;
    let amount = document.getElementById("amount").value;
    let wallet_destino = document.getElementById("wallet").value;

    const contract_token = this.state.contrato.tokenList[idMoneda];

    let balance = parseInt((await contract_token.balanceOf(this.state.wallet).call())._hex)

    amount = amount.replace(",", ".")
    amount = parseFloat(amount);
    amount = new BigNumber(amount).shiftedBy(tokenContratos[idMoneda].decimals).dp(0)

    if (!approved) {

      this.setState({
        modalTitle: "Approval Token Alert",
        modalBody: <p>It is the first time that you are going to make a shipment with this Token, this first transaction will be more expensive because it is an external contract
          <br></br><button className="btn btn-success" onClick={() => this.approvalToken()}>Continue to Approval Token</button><br></br>
        </p>
      })

      window.$("#exampleModal").modal("show");

      return;

    }

    if (!this.state.contrato['web3'].isAddress(wallet_destino) || wallet_destino === this.state.wallet) {
      this.setState({
        modalTitle: "Input error",
        modalBody: "Invalid Wallet"
      })

      window.$("#exampleModal").modal("show");
      return;
    }

    if (amount.toNumber() <= 0) {
      this.setState({
        modalTitle: "Input error",
        modalBody: "Invalid amount"
      })

      window.$("#exampleModal").modal("show");
      return;
    }

    if (this.state.energyContrato >= limiteEnergy) {

      if (balance >= amount.toNumber()) {

        this.setState({
          modalTitle: <>Sing transaction {imgLoading}</>,
          modalBody: <>Please Sing the transaction from your wallet </>
        })

        window.$("#exampleModal").modal("show");

        let inputs = [
          { type: 'address', value: window.tronWeb.address.toHex(wallet_destino) },
          { type: 'uint256', value: amount.toString(10) },
          { type: 'uint256', value: idMoneda }

        ]

        let funcion = "transfer(address,uint256,uint256)"
        const options = {  }//{ callValue: amount , feeLimit: 1 * 10**6}
        let trigger = await tronWeb.transactionBuilder.triggerSmartContract(window.tronWeb.address.toHex(this.state.contrato.ciro_trx.address), funcion, options, inputs, window.tronWeb.address.toHex(this.state.wallet))
          .catch((e) => {

            this.setState({
              modalTitle: "error",
              modalBody: e.toString()
            })

            window.$("#exampleModal").modal("show");
          })
        let transaction = await tronWeb.transactionBuilder.extendExpiration(trigger.transaction, 180);
        transaction = await adapter.signTransaction(transaction)
          .catch((e) => {

            this.setState({
              modalTitle: "error",
              modalBody: e.toString()
            })

            window.$("#exampleModal").modal("show");
            return false;
          })
        transaction = await tronWeb.trx.sendRawTransaction(transaction)
          .then((r) => {
            this.setState({
              ModalTitulo: "Transaction",
              ModalBody: <>sent to the blockchain</>
            })

            window.$("#exampleModal").modal("show");
            return r;
          })

        //await this.state.contrato.ciro_trx.transfer(wallet, amount.toString(10), idMoneda).send();

        this.setState({
          modalTitle: <>Waiting for the blockchain {imgLoading}</>,
          modalBody: "We are waiting for the blockchain to process and confirm your transfer. This can take from 5 seconds to 1 minute"
        })

        window.$("#exampleModal").modal("show");
        await delay(5)

        let result = await this.state.contrato['web3'].trx.getTransaction(transaction.txid);

        if (result.ret[0].contractRet === "SUCCESS") {
          this.setState({
            modalTitle: "Transacction successful",
            modalBody: <>Your send is done!<br />
              <a href={"https://tronscan.io/#/transaction/" + transaction.txid} target="_blank" rel="noreferrer noopener">See on TronScan</a></>
          })

        } else {
          this.setState({
            modalTitle: "Transacction failed",
            modalBody: "Apparently there was an error in the transaction, please contact support. hash: " + result.ret[0].txId
          })

        }

        window.$("#exampleModal").modal("show");

        document.getElementById("amount").value = "";




      } else {

        this.setState({
          modalTitle: "input error",
          modalBody: "You don't have enough tokens to make the payment"
        })

        window.$("#exampleModal").modal("show");

        document.getElementById("amount").value = "";

      }

    } else {

      this.setState({
        modalTitle: "Sold Out",
        modalBody: "All resources have been sold to carry out transactions paid for with stable tokens"
      })

      window.$("#exampleModal").modal("show");

    }

    this.estado();

  };




  render() {

    let { isChecked, energyContrato, tokenSelected, recivedAmount, approved } = this.state

    let status = energyContrato > limiteEnergy ? "🟢" : "🔴"


    let ButtonSend = (
      <button type="button" style={{ width: "100%" }} onClick={() => {
        this.send();
      }}>Send {recivedAmount} {tokenSelected}
      </button>
    )

    if (!approved) {
      ButtonSend = (<button className="btn btn-secondary" type="button" style={{ width: "100%" }} onClick={() => {
        this.send();
      }}>Approval spend {tokenSelected}
      </button>)

    }


    return (

      <>
        <div className="contact-form-area style-two pt-100 pb-100">

          <div className="container">
            <div className="row">
              <div className="dreamit-section-title text-center upper1 pb-70">
                <h4>CiroTrx</h4>
                <h1 className="section-title">Stablecoin on TRON made simple</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-none d-sm-block">
                <div className="contact-form-thumb wow fadeInRight" data-wow-delay=".4s">
                  <img src="assets/images/resource/CIROTRX.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="contact-form-box wow fadeInLeft" data-wow-delay=".4s">
                    <div className="contact-form-title">
                      <div className="container">
                        <div className="row">
                          <div className="col-6">
                            <h3>CiroTrx  </h3>

                          </div>
                          <div className="col-6">
                            <p style={{ fontSize: "0.9rem", color: "#808080" }}><span className="text-right" >Status: {status} </span></p>

                          </div>

                        </div>
                      </div>

                    </div>
                    <form id="dreamit-form">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Recipient wallet</p>
                          <div className="from-box">
                            <input type="text" id="wallet" placeholder="Paste or enter the account address" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Choose a stablecoin</p>

                          <div className="from-box">
                            <div className="row">
                              <div className="col-2">
                                <img className="" style={{ margin: "7px 20px", width: "35px", height: "35px" }} src={"assets/images/" + this.state.tokenSelected + "-logo.png"} alt={this.state.tokenSelected + "-logo"} />
                              </div>
                              <div className="col-10">
                                <select name="select" id="token" onChange={() => { this.opciones() }} style={{ padding: "6px 20px", borderRadius: "30px", width: "100%", height: "54px", marginBottom: "20px", backgroundColor: "transparent", color: "#8e8e8e", border: "1px solid #353D51" }}>
                                  {this.state.elementSelect}
                                </select>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white" style={{ fontSize: "0.9rem", color: "#808080" }}>Available: {this.state.tokenBalance.dp(this.state.tokenContratos[this.state.idMoneda].decimals).toString(10)} {this.state.tokenSelected}</p>

                          <div className="from-box">
                            <input type="text" id="amount" onChange={() => { this.opciones() }} pattern="[0-9]*" placeholder={this.state.tokenBalance.dp(this.state.tokenContratos[this.state.idMoneda].decimals).toString(10) + " " +this.state.tokenSelected} min={0} step={0.01} a/>
                          </div>

                        </div>

                        <div className="col-lg-12 col-sm-12 btn-group" role="group" >
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = (this.state.tokenBalance).times(0.25).dp(6).toString(10); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>25%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = (this.state.tokenBalance).times(0.50).dp(6).toString(10); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>50%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = (this.state.tokenBalance).times(0.75).dp(6).toString(10); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>75%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = (this.state.tokenBalance).times(1).dp(6).toString(10); this.opciones() }} style={{ borderRadius: "10px", backgroundColor: "#1DD1A1" }}>100%</button>

                        </div>

                        <div className="col-lg-12 col-sm-12">
                          <div className="from-box mt-3">
                            {ButtonSend}

                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12 text-center">
                          <p style={{ fontSize: "0.9rem", color: "#808080" }}>Add fee on send{" "}
                            <input type='checkbox' checked={isChecked} onChange={async () => {

                              await this.setState({ isChecked: this.state.isChecked ? false : true })
                              this.opciones(true);

                            }}>
                            </input></p>

                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="exampleModal" style={{ zIndex: 999 }} aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLabel">{this.state.modalTitle}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {this.state.modalBody}
              </div>
              <div className="modal-footer">

              </div>
            </div>
          </div>
        </div>

      </>

    );
    //<button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
  }
}


export default CiroTrx;
