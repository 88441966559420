import React, { useState, useEffect, useReducer } from "react";
const BigNumber = require('bignumber.js');

const baseTrx = new BigNumber(7)
const forSale = new BigNumber(10000)
const baseEnergy = new BigNumber(500000)
const factorMin = new BigNumber(.1)
const pasoFactor = new BigNumber(100000)

function reducer(state, action) {

    if(isNaN(action) || action.toNumber() < 1) action = new BigNumber(0)

    action = new BigNumber(action.toNumber())

    if(action.gte(baseEnergy)){
        action = baseEnergy
    }

    return action
  }

  

function Buye() {

    const [energiaRequerida, setEnergiaRequerida] = useState(baseEnergy)
    const [trxRecibe, setTrxRecibe] = useState(baseTrx)
    const [energiaEnVenta, setEnergiaEnVenta] = useReducer(reducer,forSale)

    useEffect(() => {

        setEnergiaRequerida(baseEnergy) //consultar cuanta energia necesitamos
    
        let precio = new BigNumber(baseTrx).times(1-(factorMin*(parseInt(energiaRequerida)%pasoFactor))).dividedBy(forSale)

        if(parseInt(energiaEnVenta > energiaRequerida.toNumber())){
            setTrxRecibe(precio.times(energiaRequerida).dp(6));

        }else{
            setTrxRecibe(precio.times(parseInt(energiaEnVenta)).dp(6));

        }

        return () => {
          //Callback a ejecutar en el proceso de desmontaje
        }
      }, [energiaEnVenta]) // eslint-disable-line react-hooks/exhaustive-deps

     
      const sellEnergy = () => {

        alert("vendiendo energia")

      }

    return (<>
        <div className="contact-form-area style-two pt-100 pb-100">
            <div className="container">
                <div className="row">
                    <div className="dreamit-section-title text-center upper1 pb-70">
                        <h1 className="section-title">Earn TRX Rewards by Lending Your Energy</h1>
                        <p>Maximize your unused energy! Delegate your energy to CiroTRX for 30 days and receive instant TRX rewards directly to your wallet.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                        <div className="contact-form-box wow fadeInUp text-center" data-wow-delay=".4s">
                            <div className="contact-form-title">
                                <h3 className="pb-4">Delegate Energy & Earn</h3>
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="energy-info">
                                        <h6>Energy Needed: <span id="energy-needed">{energiaRequerida.toString(10)}</span></h6>
                                    </div>0
                                    <div className="energy-info">
                                        <h6>Energy Available: <span id="energy-available">000000</span></h6>
                                    </div>
                                </div>
                            </div>
                            <form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 mb-4">
                                        <div className="from-box">
                                            <input value={energiaEnVenta} onChange={e => setEnergiaEnVenta(new BigNumber(e.target.value)) } type="text"  name="energy" placeholder="Energy to Delegate" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-12 mb-4">
                                        <h6 id="trx-reward">You will receive: {trxRecibe.toString(10)} TRX</h6>
                                    </div>
                                </div>
                                <div className="from-box">
                                    <button type="button" className="btn btn-primary w-100" onClick={sellEnergy}>Delegate Now!</button>
                                </div>
                            </form>
                            <div id="status"></div>

                            <div className="small-text mt-3">
                                <h6><small><em style={{color:"white"}}>Energy will be locked for a period of 30 days, and the prices shown are for this period only.</em></small></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Buye;