import React from "react";

import Footer from "./components/footer.jsx";
import Nav from "./components/navbar.jsx";

import Inicio from "./pages/inicio.jsx";
import Cirotrx from "./pages/cirotrx.jsx";
import Buye from "./pages/buy-energy.jsx";
import TC from "./pages/terms-and-condition.jsx";
import PP from "./pages/privacy-policy.jsx";
import WP from "./pages/white-paper.jsx";


const Layout = ({children}) => {
  
  return (<>
    <Nav/>
    {children}
    <Footer />
  </>)
}

const Seleccionador = ()=>{

  let ruta = ()=>{return <></>}

  let url = window.location.href;

  if (url.indexOf("/?") >= 0) url = (url.split("/?"))[1];
  if (url.indexOf("&") >= 0) url = (url.split("&"))[0];
  if (url.indexOf("#") >= 0) url = (url.split("#"))[0];

  url = url.toLocaleLowerCase()

  switch (url) {

    case "send":
    case "a":
    case "app":
    case "dapp":
      ruta = <Cirotrx />
      break;

    case "e":
    case "rent":
    case "energy":
    case "earn":
      ruta = <Buye/>
      break;

    case "tyc":
    case "tc":
    case "terms-and-conditions":
    case "terms-conditions":
      ruta = <TC/>
      break;

    case "pp":
    case "privacy":
    case "policy":
    case "privacy-policy":
      ruta = <PP/>
      break;

    case "wp":
    case "white":
    case "paper":
    case "white-paper":
      ruta = <WP/>
      break;

    default:
      ruta = <Inicio />
      break;
  }

  return <Layout>{ruta}</Layout>

}

function App() {

  return <Seleccionador />

}


export default App;